//
// Print Mode
//

@page {
    size: letter !important;
    // margin: 18mm 15mm 8mm 15mm !important;
  }

@media print {
    address{
        font-size: 12pt;
    }
    #kt_header_mobile > a{
        width: 100%;
        display: flex;
        align-items: center;
        margin: 40px;
        justify-content: center;

    }
    .logo-default{
        margin: 40px auto;

        
        
    }

    p.h3{
        font-size: 15pt !important;
        font-weight: bold;
        margin-bottom: 1em;
    }

    table{
        font-size: 12pt
    }

    .text-muted.text-center{
        font-size: 14pt !important;
        margin-top: 2em;
        font-weight: bolder;
        text-align: center;
    }

    .d-print-none {
      display: none !important; }
    .d-print-inline {
      display: inline !important; }
    .d-print-inline-block {
      display: inline-block !important; }
    .d-print-block {
      display: block !important; }
    .d-print-table {
      display: table !important; }
    .d-print-table-row {
      display: table-row !important; }
    .d-print-table-cell {
      display: table-cell !important; }
    .d-print-flex {
      display: flex !important; }
    .d-print-inline-flex {
      display: inline-flex !important; } 
    

    .table thead th, .markdown > table thead th {
        background: transparent; 
    } 
    
    .card-body {
        padding: 0;
    }
    
    .card {
        border: none;
        box-shadow: none; 
    } 
    
    .content {
        margin: 0 !important; 
    } 
    
    body {
        background: transparent; 
    } 

    .print-content-only {
        padding: 0 !important;
        background: none !important;

        .wrapper,
        .page,
        .content,
        .container,
        .container-fluid {
            background: none !important;
            padding: 0 !important;
            margin: 0 !important;
        }

        .aside,
        .sidebar,
        .sticky-toolbar,
        .scrolltop,
        .header,
        .header-mobile,
        .footer,
        .subheader,
        .offcanvas {
            display: none !important;
        }
    }
}
