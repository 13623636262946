// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
.no-margin-bottom{
  margin-bottom:0
}

.plan-card-description > .plan-desc-item:not(:last-child){
  border-bottom: 1px solid #efecec;
  
}

.featured .plan-card-price {
  color:white !important;
  background-color: #11827c !important;
}

// .mapboxgl-ctrl-group.mapboxgl-ctrl{
//   display: none !important;
// }

.table-image{
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.table-image-full{
  height: 50px;
  border: 1px solid black;
}
.featured  {
  transform: scale(1.05) !important;
}

.featured .plan-card-arrow {
  color:white !important;
  background-color: #11827c !important;
  margin-top: -1px;
}
.featured .plan-card-header {
  color:white !important;
  background-color: #0f6e6a !important;
}


.apexcharts-canvas{
  svg{
    overflow: initial !important;
  }
}

textarea{
  min-height: 100px;
}


.plan-card {
  margin-bottom:2em;
  border-radius: 0.42rem;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #e4e4e4;

  .plan-card-header{
    text-transform: uppercase;
    padding: 1rem;
    color: #18a39c;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    
  }

  .plan-card-body{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    .plan-card-description{
      width: 100%;
      position: relative;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 2em;
      // &:before{
      //   content: "";
      //   width: 75%;
      //   height: 100px;
      //   position: absolute;
      //   background-color: red;
      //   left: 1em;
      //   z-index: 1;
      //   top:-3rem;
      //   transform: rotate(10deg) translateX(-35%) ;
      // }
      // &:after{
      //   content: "";
      //   width: 75%;
      //   height: 100px;
      //   position: absolute;
      //   background-color: red;
      //   right: 1em;
      //   z-index: 1;
      //   top: -3rem;
      //   transform: rotate(-10deg) translateX(35%) ;
      // }

      .plan-desc-item{
        
        padding-top: 1em;
        padding-bottom: 1em;
        text-align: center;
        width: 100%;
        overflow:hidden;
        word-break: break-word;
      }
    }

    .plan-card-arrow{
      height: 50px;
      width: 140%;
      position: relative;
      display: flex;
      justify-content: center;
      background-color: #e4e4e4;
      .left{
        height: 100px;
        width: 70%;
        background-color: white;
        transform: rotate(10deg) translateY(20%) translateX(20%);
      }

      .right{
        height: 100px;
        width: 70%;
        background-color: white;
        transform: rotate(-10deg) translateY(20%) translateX(-20%);
      }
    }

    .plan-card-price
    {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      color:rgb(31, 31, 31);
      background-color: #e4e4e4;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 2em;
      text-align: center;
      
      .decimals{
        margin-top: -2em;
      }

      h1{
        
        font-size: 2.5em;
        font-weight: 900;
      }
    }
     
  }
  .plan-card-footer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    border-top: 1px solid #e4e4e4;
  }
}

.plan

.add-pic-button{
  border-radius: 5px;
	display: flex;
    width: 80px;
    border: 1px solid silver;
    height: 80px;
    background-size: cover;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .avatar-upload{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    
}

.image-list-preview{
  cursor: pointer;
}

.figure-img {
  margin-bottom: 0rem !important;
}

.image-list-preview.selected{
  border:1px solid #1BC5BD;
}


.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.symbol-label.no-back{
  background-color: rgba(0, 0, 0, 0) !important;
}

.symbol-60-60 {
  width: 60px !important;
  height: 60px !important;
}

.margin-reducer{
  margin-right: 12.5px !important;
  margin-left: 12.5px !important;
  height: 100%;
  border-radius: 0.42rem;
}

.details-mini-card{
  // border:.5px solid rgb(241, 241, 241);
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
}

.details-mini-card2{
  // border:.5px solid rgb(241, 241, 241);
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}